import { render, staticRenderFns } from "./StoryItem.vue?vue&type=template&id=493564de&scoped=true&"
import script from "./StoryItem.ts?vue&type=script&lang=ts&"
export * from "./StoryItem.ts?vue&type=script&lang=ts&"
import style0 from "./StoryItem.vue?vue&type=style&index=0&id=493564de&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "493564de",
  null
  
)

export default component.exports