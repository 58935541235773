import { render, staticRenderFns } from "./UserItem.vue?vue&type=template&id=076bf01d&scoped=true&"
import script from "./UserItem.ts?vue&type=script&lang=ts&"
export * from "./UserItem.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "076bf01d",
  null
  
)

export default component.exports